import { EventEmitter } from 'events';
import { Player, VendorPlayer } from '../player';

const PLAYER_POSITION_SAME_COUNT = 3;

/**
 * [[PlayerPositionObserver]] monitors the [[Player]]'s position while it is
 * in the [[Player.State.Playing]] state, and raises an event if it is the same
 * for the last PLAYER_POSITION_SAME_COUNT continuous samples. This is required
 * when running in Firefox because the [[Player]] does not transition to the
 * [[Player.State.Ended]] state after the MediaProcessor is ended.
 * @private
 */
export class PlayerPositionObserver extends EventEmitter {
  private _playerPositions: number[];
  private _telemetry: Player.Telemetry;
  private _vendorPlayer: VendorPlayer;

  /**
   * @private
   */
  constructor(vendorPlayer: VendorPlayer, telemetry: Player.Telemetry) {
    super();
    this._playerPositions = [];
    this._telemetry = telemetry;
    this._vendorPlayer = vendorPlayer;

    this._telemetry.subscribe(this._onSummary, ({ name, type }) => {
      return type === 'playback-quality'
        && name === 'summary'
        && this._vendorPlayer.getState() === 'Playing';
    });
  }

  release() {
    this.removeAllListeners(PlayerPositionObserver.Event.PlayerPositionSame);
  }

  private _onSummary = (data: Player.Telemetry.Data): void => {
    const summary = data as Player.Telemetry.Data.PlaybackQuality.Summary;
    this._playerPositions.push(summary.playerPosition);
    this._playerPositions.splice(0, Number(this._playerPositions.length > PLAYER_POSITION_SAME_COUNT));
    if (this._playerPositions.length === PLAYER_POSITION_SAME_COUNT
      && new Set(this._playerPositions).size === 1) {
      this._telemetry.unsubscribe(this._onSummary);
      this.emit(PlayerPositionObserver.Event.PlayerPositionSame);
    }
  }
}

/**
 * @private
 */
export namespace PlayerPositionObserver {
  /**
   * @private
   */
  export enum Event {
    PlayerPositionSame = 'player-position-same',
  }
}
