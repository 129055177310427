import {
  isSupported,
  MediaPlayer,
} from './mediaplayer';

import {
  Player,
  setDerivedPlayer,
  setIsPlayerSupported,
} from './player';

setDerivedPlayer(MediaPlayer);
setIsPlayerSupported(isSupported);

window.Twilio = window.Twilio || {};
window.Twilio.Live = window.Twilio.Live ||  { Player };
window.Twilio.Live.Player = window.Twilio.Live.Player || Player;

export { Player };
