import { EventEmitter } from 'events';
import { exponential as exponentialBackoff, Backoff } from 'backoff';
import { ErrorType, PlayerError, PlayerEventType, PlayerState } from 'amazon-ivs-player';
import { VendorPlayer } from '../player';

const BACKOFF_TIMEOUT_MS = 16000;

const BACKOFF_CONFIG = {
  factor: 1.50,
  initialDelay: 1000,
  maxDelay: 8000,
  randomisationFactor: 0.5,
};

const RETRYABLE_ERRORS: { [key: number]: ErrorType } = {
  404: ErrorType.NOT_AVAILABLE
};

/**
 * [[PlaybackUrlEventObserver]] listens to the vendor player errors after loading the playback url.
 * The observer will then re-emit the events or retry loading the playback url base on the retry policy.
 * @private
 */
export class PlaybackUrlEventObserver extends EventEmitter {

  private _playbackUrl: string;
  private _startTime?: number;
  private _timer: Backoff | null;
  private _vendorPlayer: VendorPlayer;

  constructor(
    vendorPlayer: VendorPlayer,
    playbackUrl: string,
    options?: { exponentialBackoff?: () => Backoff }) {

    super();
    options = options || {};

    this._playbackUrl = playbackUrl;
    this._vendorPlayer = vendorPlayer;
    this._vendorPlayer.addEventListener(PlayerEventType.ERROR, this._onError);
    this._vendorPlayer.addEventListener(PlayerState.READY, this._timerDone);

    this._timer = (options.exponentialBackoff || exponentialBackoff)(BACKOFF_CONFIG);
    this._timer.on('ready', this._onRetry);
  }

  release(): void {
    this._clearTimer();
    this.removeAllListeners(PlayerEventType.ERROR);
    this._vendorPlayer.removeEventListener(PlayerState.READY, this._timerDone);
    this._vendorPlayer.removeEventListener(PlayerEventType.ERROR, this._onError);
  }

  private _clearTimer() {
    if (this._timer) {
      this._timer.reset();
      this._timer.removeAllListeners('ready');
      this._timer = null;
    }
  }

  private _onError = (error: PlayerError) => {
    const type = RETRYABLE_ERRORS[error.code];
    const isRetryable = !!this._timer && !!type && type === error.type;
    const hasTimedOut = !!this._startTime && Date.now() - this._startTime >= BACKOFF_TIMEOUT_MS;

    if (isRetryable && hasTimedOut) {
      this._timerDone();
      this.emit(PlayerEventType.ERROR, error);
      return;
    }

    if (isRetryable) {
      if (!this._startTime) {
        this._startTime = Date.now();
      }
      this._timer!.backoff();
      return;
    }
    // Not retryable. We bubble up.
    this.emit(PlayerEventType.ERROR, error);
  }

  private _onRetry = () => {
    this._vendorPlayer.load(this._playbackUrl);
  }

  private _timerDone = () => {
    this._clearTimer();
    this._vendorPlayer.removeEventListener(PlayerState.READY, this._timerDone);
  }
}
