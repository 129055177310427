import { Error } from './error';
import { RequestCredentials } from './types';
import { decodeBase64Str } from './util';

const { AccessTokenInvalidError } = Error;

/**
 * Decode the given access token and return the playback grant.
 * @private
 */
export function getPlaybackGrant(token: string): {
  playbackUrl: string;
  requestCredentials?: RequestCredentials;
  streamerSid: string;
} {
  let playbackUrl;
  let streamerSid;
  let requestCredentials;

  try {
    const playbackGrant = JSON.parse(decodeBase64Str(token.split('.')[1])).grants.player;
    playbackUrl = playbackGrant.playbackUrl;
    streamerSid = playbackGrant.playerStreamerSid;
    requestCredentials = playbackGrant.requestCredentials;

    if (!playbackUrl || !streamerSid || (typeof requestCredentials === 'string'
      && !['omit', 'same-origin', 'include'].includes(requestCredentials))) {
      throw null;
    }
  } catch {
    throw new AccessTokenInvalidError();
  }

  return {
    playbackUrl,
    requestCredentials,
    streamerSid,
  };
}
