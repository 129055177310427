import { Player } from './player';
import { Error as PlayerError } from './error';
import { RequestCredentials } from './types';

/**
 * A [[Telemetry]] provides facilities for subscribing to event
 * and metric data collected by the SDK.
 */
export class Telemetry {
  private readonly _subscribersToPredicates: Map<Telemetry.Subscriber, Telemetry.Predicate>;

  /**
   * @private
   */
  constructor() {
    this._subscribersToPredicates = new Map<
      Telemetry.Subscriber,
      Telemetry.Predicate
    >();
  }

  /**
   * @private
   */
  publish(data: Telemetry.Data): this {
    this._subscribersToPredicates.forEach((predicate, subscriber) => {
      if (predicate(data)) {
        subscriber(data);
      }
    });
    return this;
  }

  /**
   * @private
   */
  publishPeriodically(getData: () => Telemetry.Data, periodMs: number): {
    start: () => void;
    stop: () => void;
  } {
    const stop = () => {
      if (interval !== null) {
        clearInterval(interval);
        interval = null;
      }
    };
    const start = () => {
      if (interval === null) {
        interval = setInterval(() => this.publish(getData()), periodMs);
      }
    };
    let interval: NodeJS.Timeout | null = null;
    return { start, stop };
  }

  /**
   * Subscribe to the published [[Telemetry.Data]] objects that satisfy the given
   * [[Telemetry.Predicate]]. If no [[Telemetry.Predicate]] is provided, all
   * [[Telemetry.Data]] objects will be subscribed to.
   * @param subscriber Consumer of the published [[Telemetry.Data]] objects
   * @param predicate The filter applied to the published [[Telemetry.Data]] objects
   */
  subscribe(subscriber: Telemetry.Subscriber, predicate?: Telemetry.Predicate): this {
    this._subscribersToPredicates.set(subscriber, predicate || (() => true));
    return this;
  }

  /**
   * Unsubscribe from the [[Telemetry.Data]] objects.
   * @param subscriber Consumer of the published [[Telemetry.Data]] objects
   */
  unsubscribe(subscriber: Telemetry.Subscriber): this {
    this._subscribersToPredicates.delete(subscriber);
    return this;
  }
}

export namespace Telemetry {
  /**
   * The common properties in all [[Telemetry]] data reported by the SDK.
   */
  export interface Data {
    /**
     * The name of the [[Telemetry]] data.
     */
    name: string;

    /**
     * The time when the data was reported.
     */
    timestamp: number;

    /**
     * The type of the [[Telemetry]] data.
     */
    type: string;
  }

  export namespace Data {
    /**
     * A type of [[Telemetry]] data pertaining to the SDK's connection to
     * a live stream.
     */
    export interface Connection extends Data {
      /**
       * The SID associated with the [PlayerStreamer](https://www.twilio.com/docs/live/playerstreamers)
       * to which the [[Player]] reporting the data is connected. It is set
       * to empty if the [[Player]] is not connected to the PlayerStreamer.
       */
      playerStreamerSid: string;

      /**
       * The type is set to "connection".
       */
      type: 'connection';
    }

    export namespace Connection {
      /**
       * [[Telemetry]] data indicating that a [[Player]] has connected
       * to a live stream.
       */
      export interface Connected extends Connection {
        /**
         * The name is set to "connected".
         */
        name: 'connected';

        /**
         * Indicates what cross-origin request policy was used for cross-site
         * cookies during media playback.
         */
        requestCredentials?: RequestCredentials;
      }

      /**
       * [[Telemetry]] data indicating that a [[Player]] is connecting
       * to a live stream.
       */
      export interface Connecting extends Connection {
        /**
         * The name is set to "connecting".
         */
        name: 'connecting';
      }

      /**
       * [[Telemetry]] data indicating that a [[Player]] has disconnected
       * from a live stream.
       */
      export interface Disconnected extends Connection {
        /**
         * The name is set to "disconnected".
         */
        name: 'disconnected';
      }

      /**
       * [[Telemetry]] data indicating that a [[Player]] experienced an error
       * while connecting to a live stream.
       */
      export interface Error extends Connection {
        /**
         * The name is set to "error".
         */
        name: 'error';

        /**
         * The [[Player.Error]] describing the error.
         */
        playerError: PlayerError;
      }
    }

    /**
     * [[Telemetry]] data pertaining to media playback.
     */
    export interface Playback extends Data {
      /**
       * The [[Player]] position at the time the data was reported.
       */
      playerPosition: number;

      /**
       * The [[Player]]'s state at the time the data was reported.
       */
      playerState: Player.State;

      /**
       * The SID associated with the [PlayerStreamer](https://www.twilio.com/docs/live/playerstreamers)
       * to which the [[Player]] is connected.
       */
      playerStreamerSid: string;

      /**
       * The type is set to "playback".
       */
      type: 'playback';
    }

    export namespace Playback {
      /**
       * [[Telemetry]] data indicating that a [[Player]] experienced an error
       * while playing back a live stream.
       */
      export interface Error extends Playback {
        /**
         * The name is set to "error".
         */
        name: 'error';

        /**
         * The [[Player.Error]] describing the error.
         */
        playerError: PlayerError;
      }

      /**
       * [[Telemetry]] data indicated that the player has been muted.
       */
      export interface Muted extends Playback {
        /**
         * The name is set to "muted".
         */
        name: 'muted';
      }

      /**
       * [[Telemetry]] data indicating that the Player has been paused.
       */
      export interface Paused extends Playback {
        /**
         * The name is set to "played".
         */
        name: 'paused';
      }

      /**
       * [[Telemetry]] data indicating that the Player has been played.
       */
      export interface Played extends Playback {
        /**
         * The name is set to "played".
         */
        name: 'played';
      }

      /**
       * [[Telemetry]] data indicating that the Player is rebuffering.
       */
      export interface Rebuffering extends Playback {
        /**
         * The name is set to "rebuffering".
         */
        name: 'rebuffering';
      }

      /**
       * [[Telemetry]] data indicating that the [[Player]]
       * seeked to a given position (as requested by [[Player.seekTo]]).
       */
      export interface SeekCompleted extends Playback {
        /**
         * The name is set to "seek-completed".
         */
        name: 'seek-completed';
      }

      /**
       * [[Telemetry]] data indicating that the Player started seeking to a specified position.
       */
      export interface SeekTo extends Playback {
        /**
         * The previous position of the [[Player]].
         */
        from: number;

        /**
         * The name is set to "seek-to".
         */
        name: 'seek-to';

        /**
         * The current position of the [[Player]].
         */
        to: number;
      }

      /**
       * [[Telemetry]] data indicated that the player has been unmuted.
       */
      export interface Unmuted extends Playback {
        /**
         * The name is set to "unmuted".
         */
        name: 'unmuted';
      }

      export interface VolumeSet extends Playback {
        /**
         * The previous volume of the [[Player]].
         */
        from: number;

        /**
         * The name is set to "volume-set".
         */
        name: 'volume-set';

        /**
         * The current volume of the [[Player]].
         */
        to: number;
      }
    }

    export interface PlaybackQuality extends Data {
      /**
       * The [[Player]] live latency (in seconds) at the time the data was reported.
       */
      playerLiveLatency: number;

      /**
       * The [[Player]] position at the time the data was reported.
       */
      playerPosition: number;

      /**
       * The SID associated with the [PlayerStreamer](https://www.twilio.com/docs/live/playerstreamers)
       * to which the [[Player]] is connected.
       */
      playerStreamerSid: string;

      /**
       * The [[Player]]'s volume at the time the data was reported.
       */
      playerVolume: number;

      /**
       * The type is set to "playback-quality".
       */
      type: 'playback-quality';
    }

    export namespace PlaybackQuality {
      /**
       * [[Telemetry]] data indicating that the [[Player]]'s
       * [[Player.duration]] changed.
       */
      export interface DurationChanged extends PlaybackQuality {
        /**
         * The previous [[Player.duration]].
         */
        from: number;

        /**
         * The name is set to "duration-changed".
         */
        name: 'duration-changed';

        /**
         * The current [[Player.duration]].
         */
        to: number;
      }

      /**
       * [[Telemetry]] data indicating that the [[Player]]
       * applied a high latency reduction strategy.
       */
      export interface HighLatencyReductionApplied extends PlaybackQuality {
        /**
         * The name of the high latency reduction technique applied.
         */
        name: 'increase-playback-rate' | 'seek-ahead';
      }

      /**
       * [[Telemetry]] data indicating that the [[Player]]
       * has reverted all high latency reduction strategies.
       */
      export interface HighLatencyReductionReverted extends PlaybackQuality {
        /**
         * The name is set to "high-latency-reduction-reverted"
         */
        name: 'high-latency-reduction-reverted';
      }

      /**
       * [[Telemetry]] data indicating that the [[Player]]'s
       * [[Player.Quality]] changed.
       */
      export interface QualityChanged extends PlaybackQuality {
        /**
         * The previous [[Player.Quality]].
         */
        from: Player.Quality;

        /**
         * The name is set to "quality-changed".
         */
        name: 'quality-changed';

        /**
         * The current [[Player.Quality]].
         */
        to: Player.Quality;
      }

      /**
       * [[Telemetry]] data indicating that the [[Player]]'s
       * [[Player.Quality]] has been set.
       */
      export interface QualitySet extends PlaybackQuality {
        /**
         * The previous [[Player.Quality]].
         */
        from: Player.Quality;

        /**
         * The name is set to "quality-set".
         */
        name: 'quality-set';

        /**
         * The current [[Player.Quality]].
         */
        to: Player.Quality;
      }

      /**
       * [[Telemetry]] data summarizing the quality metrics of the
       * live stream playback. It is reported every three seconds
       * while the [[Player]] is in either the [[Player.State.Playing]]
       * or [[Player.State.Buffering]] states.
       */
      export interface Summary extends PlaybackQuality {
        /**
         * The name is set to "summary".
         */
        name: 'summary';

        /**
         * The snapshot of the [[Player]] stats at the time the data
         * was reported.
         */
        playerStats: Player.Stats;
      }

      /**
       * [[Telemetry]] data indicating that the live stream's playback
       * video dimensions changed.
       */
      export interface VideoSizeChanged extends PlaybackQuality {
        /**
         * The previous video dimensions.
         */
        from: Player.VideoDimensions;

        /**
         * The name is set to "video-size-changed".
         */
        name: 'video-size-changed';

        /**
         * The current video dimensions.
         */
        to: Player.VideoDimensions;
      }
    }

    /**
     * [[Telemetry]] data pertaining to the media playback state.
     */
    export interface PlaybackState extends Data {
      /**
       * The SID associated with the [PlayerStreamer](https://www.twilio.com/docs/live/playerstreamers)
       * to which the [[Player]] is connected.
       */
      playerStreamerSid: string;

      /**
       * The type is set to "playback-state".
       */
      type: 'playback-state';
    }

    export namespace PlaybackState {
      /**
       * [[Telemetry]] data indicating the [[Player]]'s state changed.
       */
      export interface Changed extends PlaybackState {
        /**
         * The previous [[Player]] state.
         */
        from: Player.State;

        /**
         * The name is set to "changed".
         */
        name: 'changed';

        /**
         * The current [[Player]] state.
         */
        to: Player.State;
      }
    }

    /**
     * [[Telemetry]] data pertaining to [[Player.TimedMetadata]].
     */
    export interface TimedMetadataTelemetry extends Data {
      /**
       * The SID associated with the [PlayerStreamer](https://www.twilio.com/docs/live/playerstreamers)
       * to which the [[Player]] is connected.
       */
      playerStreamerSid: string;

      /**
       * The type is set to "timed-metadata".
       */
      type: 'timed-metadata';
    }

    export namespace TimedMetadataTelemetry {
      /**
       * [[Telemetry]] data indicating that a [[Player.TimedMetadata]] was received.
       */
      export interface Received extends TimedMetadataTelemetry {
        /**
         * The name is set to "received".
         */
        name: 'received';

        /**
         * The time in the stream that the [[Player.TimedMetadata]] was inserted.
         */
        timedMetadataTime: number;
      }
    }
  }

  /**
   * A callback that determines if a published [[Data]] object should be
   * consumed by a [[Subscriber]].
   */
  export type Predicate = (data: Data) => boolean;

  /**
   * A callback that consumes published [[Data]] objects.
   */
  export type Subscriber = (data: Data) => void;
}
