// TODO(mmalavalli): Ensure only the version is exported, and not the rest of the package.json fields.
const { version: sdkVersion }: { version: string } = require('../package.json');

import { EventEmitter } from 'events';
import { getPlaybackGrant } from './grant';

import {
  LiveLatencyEventObserver,
  PlayerPositionObserver,
} from './eventobservers';

import { RequestCredentials } from './types';
import { Error as PlayerError } from './error';
import * as TelemetryExports from './telemetry';

export interface VendorPlayer {
  addEventListener: (event: any, callback: (...args: any[]) => void) => void;
  attachHTMLVideoElement: (videoElement: HTMLVideoElement) => void;
  delete: () => void;
  getBufferDuration: () => number;
  getDecodedFrames: () => number;
  getDroppedFrames: () => number;
  getDuration: () => number;
  getHTMLVideoElement: () => HTMLVideoElement;
  getLiveLatency: () => number;
  getPlaybackRate: () => number;
  getPosition: () => number;
  getQualities: () => VendorPlayerQuality[];
  getQuality: () => VendorPlayerQuality;
  getState: () => string;
  getVideoBitRate: () => number;
  getVolume: () => number;
  isMuted: () => boolean;
  load: (playbackUrl: string) => void;
  pause: () => void;
  play: () => void;
  removeEventListener: (event: any, callback: (...args: any[]) => void) => void;
  seekTo: (time: number) => void;
  setLogLevel: (level: any) => void;
  setMuted: (mute: boolean) => void;
  setPlaybackRate: (rate: number) => void;
  setQuality: (quality: VendorPlayerQuality) => void;
  setRebufferToLive: (rebufferToLive: boolean) => void;
  setRequestCredentials: (requestCredentials: RequestCredentials) => void;
  setVolume: (level: number) => void;
}

interface VendorPlayerConfig {
  wasmBinary: string;
  wasmWorker: string;
}

interface VendorPlayerQuality {
  bitrate: number;
  codecs: string;
  height: number;
  name: string;
  width: number;
}

// NOTE(mmalavalli): This represents the class derived from Player that
// actually consumes the vendor sdk. For unit tests, this can be set to
// a mock class using setDerivedPlayer().
let DerivedPlayer: any;

/**
 * @private
 */
export function setDerivedPlayer(Class: any): void {
  if (typeof DerivedPlayer === 'undefined') {
    DerivedPlayer = Class;
  }
}

// NOTE(csantos): Represents whether HighLatencyReduction is enabled
// for all Player instances.
let isHighLatencyReductionEnabled = true;

// NOTE(mmalavalli): This represents whether the browser is supported
// by the vendor sdk. For unit tests, this can be set to a mock value
// using setIsPlayerSupported().
let isPlayerSupported: boolean;

/**
 * @private
 */
export function setIsPlayerSupported(value: boolean): void {
  if (typeof isPlayerSupported === 'undefined') {
    isPlayerSupported = value;
  }
}

// NOTE(mmalavalli): This represents the Telemetry logger for all the
// Player instances.
const telemetry = new TelemetryExports.Telemetry();

export declare interface Player {
  /**
   * The [[Player.duration]] property has changed.
   * @param event [[Player.Event.DurationChanged]]
   * @param listener A callback that has the updated [[Player.duration]], in seconds.
   */
  on(event: Player.Event.DurationChanged,
    listener: (duration: number) => void): this;

  /**
   * The [[Player]] encountered an error while playing back the live stream.
   * @param event [[Player.Event.Error]]
   * @param listener A callback that has the [[Player.Error]]
   */
  on(event: Player.Event.Error, listener: (error: Player.Error) => void): this;

  /**
   * The [[Player]]'s playback quality changed.
   * @param event [[Player.Event.QualityChanged]]
   * @param listener A callback that has the updated [[Player.Quality]]
   */
  on(event: Player.Event.QualityChanged, listener: (quality: Player.Quality) => void): this;

  /**
   * The [[Player]] is rebuffering from a previous [[Player.State.Playing]] state.
   * @param event [[Player.Event.Rebuffering]]
   * @param listener A callback called when the event is emitted
   */
  on(event: Player.Event.Rebuffering, listener: () => void): this;

  /**
   * The player seeked to a given position (as requested by [[Player.seekTo]]).
   * @param event [[Player.Event.SeekCompleted]]
   * @param listener A callback that has the position where the seek completed, in seconds.
   */
  on(event: Player.Event.SeekCompleted,
    listener: (position: number) => void): this;

  /**
   * The [[Player]]'s state changed.
   * @param event [[Player.Event.StateChanged]]
   * @param listener A callback that has the new [[Player.State]]
   */
  on(event: Player.Event.StateChanged,
     listener: (state: Player.State) => void): this;

  /**
   * The [[Player]] received a timed metadata from the live stream source.
   * @param event [[Player.Event.TimedMetadataReceived]]
   * @param listener A callback that has the [[Player.TimedMetadata]]
   */
  on(event: Player.Event.TimedMetadataReceived,
     listener: (metadata: Player.TimedMetadata) => void): this;

  /**
   * The [[Player]]'s video size changed.
   * @param event [[Player.Event.VideoSizeChanged]]
   * @param listener A callback that has the new [[Player.VideoDimensions]]
   */
  on(event: Player.Event.VideoSizeChanged,
     listener: (videoSize: Player.VideoDimensions) => void): this;

  /**
   * The [[Player]]'s volume level changed.
   * @param event [[Player.Event.VolumeChanged]]
   * @param listener A callback that has the new volume level
   */
  on(event: Player.Event.VolumeChanged,
     listener: (level: number) => void): this;
}

/**
 * A [[Player]] controls the playback of a live stream.
 */
export abstract class Player extends EventEmitter {
  /**
   * Whether high latency reduction is enabled for all Player instances.
   * This is set to `true` by default.
   * When set to `true`, the Player SDK will periodiocally inspect `player.liveLatency`
   * and perform the following when high latency is observed:
   *
   *   1. If the live latency is between 3 and 5 seconds, the Player will increase
   * the playback rate to a value that should not be perceptible to a user.
   * The increased playback rate will allow the Player to catch up to the live source,
   * and will be reverted once the live latency drops below 3 seconds.
   * Application of this strategy may result in audio pitch distortion.
   *
   *   2. If the live latency is greater than or equal to 5 seconds,
   * the Player will seek ahead to near the end of the Player's buffered content.
   * The user will notice skips in the media content when this strategy is applied.
   */
  static get isHighLatencyReductionEnabled(): boolean {
    return isHighLatencyReductionEnabled;
  }

  /**
   * Sets whether high latency reduction is enabled for all Player instances.
   * When set to `true`, the Player SDK will periodiocally inspect `player.liveLatency`
   * and perform the following when high latency is observed:
   *
   *   1. If the live latency is between 3 and 5 seconds, the Player will increase
   * the playback rate to a value that should not be perceptible to a user.
   * The increased playback rate will allow the Player to catch up to the live source,
   * and will be reverted once the live latency drops below 3 seconds.
   * Application of this strategy may result in audio pitch distortion.
   *
   *   2. If the live latency is greater than or equal to 5 seconds,
   * the Player will seek ahead to near the end of the Player's buffered content.
   * The user will notice skips in the media content when this strategy is applied.
   */
   static set isHighLatencyReductionEnabled(isEnabled: boolean) {
    isHighLatencyReductionEnabled = isEnabled;
  }

  /**
   * Whether the SDK supports the browser. The SDK only supports
   * browsers which are capable of running WebAssembly (WASM).
   */
  static get isSupported(): boolean {
    return isPlayerSupported;
  }

  /**
   * The SDK's log level.
   */
  static get logLevel(): Player.LogLevel {
    return logLevel;
  }

  /**
   * A [[Telemetry]] provides facilities for subscribing to event
   * and metric data collected by the SDK.
   */
  static get telemetry(): Player.Telemetry {
    return telemetry;
  }

  /**
   * The SDK version.
   */
  static get version(): string {
    return sdkVersion;
  }

  /**
   * Connect to a live stream.
   * @throws [[Player.Error]] or TypeError
   * @param token The access token used to connect to the live stream
   * @param options The options for creating a [[Player]]
   */
  static async connect(token: string, options: Player.Options): Promise<Player> {
    const connecting: Player.Telemetry.Data.Connection.Connecting = {
      name: 'connecting',
      playerStreamerSid: '',
      timestamp: Date.now(),
      type: 'connection',
    };
    telemetry.publish(connecting);

    try {
      const {
        playbackUrl,
        streamerSid: playerStreamerSid,
        requestCredentials,
      } = getPlaybackGrant(token);
      const connected: Player.Telemetry.Data.Connection.Connected = {
        name: 'connected',
        playerStreamerSid,
        requestCredentials,
        timestamp: Date.now(),
        type: 'connection',
      };
      telemetry.publish(connected);
      return new DerivedPlayer(playbackUrl, playerStreamerSid, { ...options, requestCredentials });
    } catch (error) {
      const connectionError: Player.Telemetry.Data.Connection.Error = {
        name: 'error',
        playerError: error,
        playerStreamerSid: '',
        timestamp: Date.now(),
        type: 'connection',
      };
      telemetry.publish(connectionError);
      throw error;
    }
  }

  /**
   * Set the SDK's log level.
   */
  static setLogLevel(level: Player.LogLevel): void {
    logLevel = level;
    const vendorPlayerLogLevel = level === Player.LogLevel.Off
      ? Player.LogLevel.Error : level;
    vendorPlayers.forEach(vendorPlayer =>
      vendorPlayer.setLogLevel(vendorPlayerLogLevel));
  }

  protected readonly _playbackUrl: string;
  protected readonly _streamerSid: string;
  protected readonly _vendorPlayer: VendorPlayer;
  private _disconnected: boolean;
  private _liveLatencyEventObserver: LiveLatencyEventObserver;
  private _playerPositionObserver: PlayerPositionObserver;
  private _previousVideoSize: Player.VideoDimensions;
  private readonly _stopRemittingVendorPlayerEvents: () => void;

  protected constructor(
    playbackUrl: string,
    streamerSid: string,
    createVendorPlayer: (config: VendorPlayerConfig) => VendorPlayer,
    options: Player.Options) {
    super();

    const {
      playerWasmAssetsPath,
      rebufferToLive = true,
      requestCredentials,
      vendorPlayerVersion,
    } = options;

    const suffix = vendorPlayerVersion!.replace(/\./g, '-');

    this._disconnected = false;
    this._playbackUrl = playbackUrl;
    this._streamerSid = streamerSid;

    this._vendorPlayer = createVendorPlayer({
      wasmBinary: `${playerWasmAssetsPath}/twilio-live-player-wasmworker-${suffix}.min.wasm`,
      wasmWorker: `${playerWasmAssetsPath}/twilio-live-player-wasmworker-${suffix}.min.js`,
    });

    // NOTE(mmalavalli): Configuring the default HTMLVideoElement for inline
    // playback on iOS Safari.
    const videoElement = this._vendorPlayer.getHTMLVideoElement();
    videoElement.playsInline = true;

    this._vendorPlayer.setLogLevel(Player.logLevel);
    vendorPlayers.add(this._vendorPlayer);

    this._vendorPlayer.setRebufferToLive(rebufferToLive);
    this.videoElement.addEventListener('resize', this._onVideoSizeChanged);
    this._previousVideoSize = { ...this.videoSize };
    this._stopRemittingVendorPlayerEvents = this._reemitVendorPlayerEvents();

    if (requestCredentials) {
      this._vendorPlayer.setRequestCredentials(requestCredentials);
    }
    this._vendorPlayer.load(playbackUrl);

    this._liveLatencyEventObserver = new LiveLatencyEventObserver(
      this._vendorPlayer,
      telemetry,
      isHighLatencyReductionEnabled);

    this._playerPositionObserver = new PlayerPositionObserver(
      this._vendorPlayer,
      telemetry);

    this._playerPositionObserver.once(
      PlayerPositionObserver.Event.PlayerPositionSame,
      () => this._disconnect());

    this._handleLiveLatencyEvents();
  }

  /**
   * Array of available [[Quality]] objects from the loaded source, or empty if
   * none are currently available. The qualities will be available after the
   * [[Player]] transitions to the [[State.Ready]] state. Note that this set will
   * contain only qualities capable of being played on the current device and not
   * all those present in the source stream.
   */
  get availableQualities(): Player.Quality[] {
    return this._vendorPlayer.getQualities().map(({ bitrate, codecs, height, name, width }) => ({
      bitrate,
      codecs,
      height,
      name,
      width,
    }));
  }

  /**
   * The playback duration in seconds. The duration is `Infinity`
   * if the media is a live stream. A [[Player.Event.DurationChanged]] is emitted
   * whenever the playback duration changes.
   */
   get duration(): number {
    return this._vendorPlayer.getDuration();
  }

  /**
   * Whether the [[Player]] is muted. You can also mute the [[Player]] by setting
   * it to true, or unmute by setting it to false. Updating this property has no
   * effect once the [[Player]] transitions to the [[Player.State.Ended]] state.
   */
  get isMuted(): boolean {
    return this._vendorPlayer.isMuted();
  }

  set isMuted(shouldMute: boolean) {
    this._vendorPlayer.setMuted(shouldMute);
    const playback: Player.Telemetry.Data.Playback = {
      name: shouldMute ? 'muted' : 'unmuted',
      playerPosition: this._vendorPlayer.getPosition(),
      playerState: this._getState(),
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      type: 'playback',
    };
    telemetry.publish(playback);
  }

  /**
   * For a live stream, the latency to the source in seconds.
   */
  get liveLatency(): number {
    return this._vendorPlayer.getLiveLatency();
  }

  /**
   * The playback position in seconds.
   */
  get position(): number {
    return this._vendorPlayer.getPosition();
  }

  /**
   * The current quality of the [[Player]]'s live stream. You
   * can also change the quality of the live stream by setting
   * a new [[Player.Quality]] from [[Player.availableQualities]].
   * The [[Player]] will emit a [[Player.Event.QualityChanged]] event.
   */
  get quality(): Player.Quality {
    const {
      bitrate,
      codecs,
      height,
      name,
      width,
    } = this._vendorPlayer.getQuality();

    return {
      bitrate,
      codecs,
      height,
      name,
      width,
    };
  }

  set quality(newQuality: Player.Quality) {
    const vendorPlayerQuality = this._vendorPlayer.getQualities().find(quality => {
      return quality.name === newQuality.name;
    });
    if (vendorPlayerQuality) {
      const oldQuality = this.quality;
      this._vendorPlayer.setQuality(vendorPlayerQuality);
      const qualitySet: Player.Telemetry.Data.PlaybackQuality.QualitySet = {
        from: oldQuality,
        name: 'quality-set',
        playerLiveLatency: this._vendorPlayer.getLiveLatency(),
        playerPosition: this._vendorPlayer.getPosition(),
        playerStreamerSid: this._streamerSid,
        playerVolume: this._vendorPlayer.getVolume(),
        timestamp: Date.now(),
        to: newQuality,
        type: 'playback-quality',
      };
      Player.telemetry.publish(qualitySet);
    }
  }

  /**
   * The [[Player]] state. Soon after a successful connection to a live stream,
   * the [[Player]] is in the [[Player.State.Idle]] state while it is preparing
   * the playback. Then it transitions to the [[Player.State.Ready]] state.
   */
  get state(): Player.State {
    return this._disconnected ? Player.State.Ended : this._getState();
  }

  /**
   * The media statistics of the [[Player]]'s live stream.
   */
  get stats(): Player.Stats {
    return {
      videoBitrate: this._vendorPlayer.getVideoBitRate() || 0,
      videoFramesDecoded: this._vendorPlayer.getDecodedFrames() || 0,
      videoFramesDropped: this._vendorPlayer.getDroppedFrames() || 0,
    };
  }

  /**
   * The SID of the [PlayerStreamer](https://www.twilio.com/docs/live/playerstreamers)
   * which the [[Player]] is connected to.
   */
  get streamerSid(): string {
    return this._streamerSid;
  }

  /**
   * The HTMLVideoElement used to play back the live stream.
   */
  get videoElement(): HTMLVideoElement {
    return this._vendorPlayer.getHTMLVideoElement();
  }

  /**
   * The [[Player]]'s video size.
   */
  get videoSize(): Player.VideoDimensions {
    const { videoHeight: height, videoWidth: width } = this.videoElement;
    return { height, width };
  }

  /**
   * The [[Player]]'s volume level in the range [0.0, 1.0].
   */
  get volume(): number {
    return this._vendorPlayer.getVolume();
  }

  /**
   * Set an HTMLVideoElement to play back the live stream. For iOS browsers,
   * please enable inline playback before attaching the HTMLVideoElement.
   * @example
   * ```
   * const videoElement = document.querySelector('div#container > video');
   * videoElement.playsInline = true;
   * player.attach(videoElement);
   * ```
   * @param videoElement The HTMLVideoElement to be used to play back the live stream
   */
  attach(videoElement: HTMLVideoElement): this {
    this.videoElement.removeEventListener('resize', this._onVideoSizeChanged);
    this._vendorPlayer.attachHTMLVideoElement(videoElement);
    videoElement.addEventListener('resize', this._onVideoSizeChanged);
    return this;
  }

  /**
   * Disconnect from the live stream. The [[Player]] will transition to the terminal
   * [[Player.State.Ended]] state, release all resources related to the playback of the
   * live stream, and stop emitting events.
   */
  disconnect(): this {
    if (!this._disconnect()) {
      return this;
    }
    const disconnected: Player.Telemetry.Data.Connection.Disconnected = {
      name: 'disconnected',
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      type: 'connection',
    };
    telemetry.publish(disconnected);
    return this;
  }

  /**
   * Pause the [[Player]]'s live stream. The [[Player]] transitions
   * to the [[Player.State.Idle]] state.
   */
  pause(): this {
    this._vendorPlayer.pause();
    const paused: Player.Telemetry.Data.Playback.Paused = {
      name: 'paused',
      playerPosition: this._vendorPlayer.getPosition(),
      playerState: this._getState(),
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      type: 'playback',
    };
    telemetry.publish(paused);
    return this;
  }

  /**
   * Start the playback of the [[Player]]'s live stream. The [[Player]]
   * may transition to the [[Player.State.Buffering]] state if it is buffering
   * media for playback, and will finally transition to the [[Player.State.Playing]]
   * state.
   *
   * Calling this method before [[Player.state]] transitions to [[Player.State.Ready]]
   * will not have any effect.
   */
  play(): this {
    this._vendorPlayer.play();
    const played: Player.Telemetry.Data.Playback.Played = {
      name: 'played',
      playerPosition: this._vendorPlayer.getPosition(),
      playerState: this._getState(),
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      type: 'playback',
    };
    telemetry.publish(played);

    return this;
  }

  /**
   * Instruct the Player to seek to a specified time in the stream and begins
   * playing media in that position. The player state might change to buffering
   * if there is not enough buffered content in the specified position. This method is
   * asynchronous and a [[Player.Event.SeekCompleted]] is emitted upon completion.
   * This is only supported for recorded media and will emit a [[Player.Error]] if invoked on a live media.
   * @throws [[Player.Error]]
   * @param position
   */
  seekTo(position: number): this {
    // NOTE(csantos): We only support seeking for VOD/Recorded media.
    // A media is considered VOD (Video on Demand) if the playlist is tagged as VOD.
    // If VOD tag exists, the player duration is Finite, otherwise Infinity.
    const duration = this._vendorPlayer.getDuration();
    const isVOD = typeof duration === 'number' && isFinite(duration) && duration > 0;
    if (!isVOD) {
      const error = new Player.Error.PlaybackNotSupportedError();
      this._emitPlaybackError(error);
      throw error;
    }

    if (position < 0 || position > this._vendorPlayer.getDuration() || typeof position !== 'number') {
      const error = new Player.Error.PlaybackInvalidParameterError('position must be in the range [0, player.duration]');
      this._emitPlaybackError(error);
      throw error;
    }

    if (position === this._vendorPlayer.getDuration()) {
      // NOTE(csantos): Move near the end to get the ended event
      position = this._vendorPlayer.getDuration() - 1;
    }

    const currentPosition = this._vendorPlayer.getPosition();
    this._vendorPlayer.seekTo(position);
    const seekToData: Player.Telemetry.Data.Playback.SeekTo = {
      from: currentPosition,
      name: 'seek-to',
      playerPosition: this._vendorPlayer.getPosition(),
      playerState: this._getState(),
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      to: position,
      type: 'playback',
    };
    telemetry.publish(seekToData);

    return this;
  }

  /**
   * Set the [[Player]]'s volume level in the range [0.0, 1.0]. The [[Player.volume]]
   * property will be updated asynchronously and a [[Player.Event.VolumeChanged]] is emitted
   * with the updated volume. A [[Player.Error]] will be emitted for any invalid parameters.
   * @throws [[Player.Error]]
   * @param level
   */
  setVolume(level: number): this {
    if (level < 0 || level > 1 || typeof level !== 'number') {
      const error = new Player.Error.PlaybackInvalidParameterError('Volume must be in the range [0, 1]');
      this._emitPlaybackError(error);
      throw error;
    }
    const previousLevel = this._vendorPlayer.getVolume();
    this._vendorPlayer.setVolume(level);
    const volumeSet: Player.Telemetry.Data.Playback.VolumeSet = {
      from: previousLevel,
      name: 'volume-set',
      playerPosition: this._vendorPlayer.getPosition(),
      playerState: this._getState(),
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      to: level,
      type: 'playback',
    };
    telemetry.publish(volumeSet);
    return this;
  }

  protected _disconnect(): boolean {
    if (this._disconnected) {
      return false;
    }
    this._disconnected = true;
    this.emit(Player.Event.StateChanged, this.state);
    this._release();
    return true;
  }

  protected _emitPlaybackError(error: Player.Error): this {
    this.emit(Player.Event.Error, error);
    const playbackError: Player.Telemetry.Data.Playback.Error = {
      name: 'error',
      playerError: error,
      playerPosition: this._vendorPlayer.getPosition(),
      playerState: this._getState(),
      playerStreamerSid: this._streamerSid,
      timestamp: Date.now(),
      type: 'playback',
    };
    telemetry.publish(playbackError);

    return this;
  }

  protected abstract _getState(): Player.State;
  protected abstract _reemitVendorPlayerEvents(): () => void;

  protected _release(): this {
    this._liveLatencyEventObserver.release();
    this._playerPositionObserver.release();
    this.videoElement.removeEventListener('resize', this._onVideoSizeChanged);
    this._stopRemittingVendorPlayerEvents();
    this._vendorPlayer.delete();
    vendorPlayers.delete(this._vendorPlayer);
    return this;
  }

  private _handleLiveLatencyEvents(): void {
    const getData = (name: string) => ({
      name,
      playerLiveLatency: this._vendorPlayer.getLiveLatency(),
      playerPosition: this._vendorPlayer.getPosition(),
      playerStreamerSid: this._streamerSid,
      playerVolume: this._vendorPlayer.getVolume(),
      timestamp: Date.now(),
      type: 'playback-quality',
    });

    this._liveLatencyEventObserver.on(
      LiveLatencyEventObserver.Event.HighLatencyReductionReverted,
      () => telemetry.publish(getData('high-latency-reduction-reverted')));

    this._liveLatencyEventObserver.on(
      LiveLatencyEventObserver.Event.IncreasePlaybackRate,
      () => telemetry.publish(getData('increase-playback-rate')));

    this._liveLatencyEventObserver.on(
      LiveLatencyEventObserver.Event.SeekAhead,
      () => telemetry.publish(getData('seek-ahead')));
  }

  private _onVideoSizeChanged = () => {
    this.emit(Player.Event.VideoSizeChanged, this.videoSize);
    const videoSizeChanged: Player.Telemetry.Data.PlaybackQuality.VideoSizeChanged = {
      from: this._previousVideoSize,
      name: 'video-size-changed',
      playerLiveLatency: this._vendorPlayer.getLiveLatency(),
      playerPosition: this._vendorPlayer.getPosition(),
      playerStreamerSid: this._streamerSid,
      playerVolume: this._vendorPlayer.getVolume(),
      timestamp: Date.now(),
      to: this.videoSize,
      type: 'playback-quality',
    };
    telemetry.publish(videoSizeChanged);
    this._previousVideoSize = { ...this.videoSize };
  }
}

export namespace Player {
  /**
   * Description of an error that was encountered while connecting to
   * or playing back a live stream.
   */
  export import Error = PlayerError;

  /**
   * [[Player]] events.
   */
  export enum Event {
    /**
     * The [[Player.duration]] property has changed.
     */
    DurationChanged = 'durationChanged',

    /**
     * The [[Player]] encountered an error while playing back the live stream.
     * The playback is stopped and the [[Player]] transitions to the [[Player.State.Ended]]
     * state.
     */
    Error = 'error',

    /**
     * The [[Player]]'s playback quality changed.
     */
    QualityChanged = 'qualityChanged',

    /**
     * The [[Player]] is rebuffering from a previous [[State.Playing]] state.
     */
    Rebuffering = 'rebuffering',

    /**
     * The player seeked to a given position (as requested by [[Player.seekTo]]).
     */
    SeekCompleted = 'seekCompleted',

    /**
     * The [[Player]]'s state changed.
     */
    StateChanged = 'stateChanged',

    /**
     * The [[Player]] received a [[TimedMetadata]] in the live stream.
     */
    TimedMetadataReceived = 'timedMetadataReceived',

    /**
     * The [[Player]]'s video size changed.
     */
    VideoSizeChanged = 'videoSizeChanged',

    /**
     * The [[Player]]'s volume level changed.
     */
    VolumeChanged = 'volumeChanged',
  }

  /**
   * Available log levels for the [[Player]].
   */
  export enum LogLevel {
    Debug = 'debug',
    Error = 'error',
    Info = 'info',
    Off = 'off',
    Warn = 'warn',
  }

  /**
   * [[Player]] options.
   */
  export interface Options {
    /**
     * Absolute path of the hosted "twilio-live-player-wasmworker-x-y-z.min.js"
     * and "twilio-live-player-wasmworker-x-y-z.min.wasm" files, where x.y.z is
     * the version of the files.
     */
    playerWasmAssetsPath: string;

    /**
     * @private
     */
    rebufferToLive?: boolean;

    /**
     * @private
     */
    requestCredentials?: RequestCredentials;

    /**
     * @private
     */
    vendorPlayerVersion?: string;
  }

  /**
   * The quality statistics of a [[Player]]'s live stream.
   */
  export interface Quality {
    /**
     * The bitrate of the live stream in bits per second (bps).
     */
    bitrate: number;

    /**
     * The codec string, both for audio and video tracks.
     * Example: "avc1.64002A,mp4a.40.2".
     */
    codecs: string;

    /**
     * The height of the video frames. It is set to 0 if unknown or not
     * applicable.
     */
    height: number;

    /**
     * The name of the [[Quality]] object.
     */
    name: string;

    /**
     * The width of the video frames. It is set to 0 if unknown or not
     * applicable.
     */
    width: number;
  }

  /**
   * [[Player]] states.
   */
  export enum State {
    /**
     * The [[Player]] is buffering.
     */
    Buffering = 'buffering',

    /**
     * The [[Player]] has ended the playback of the live stream.
     */
    Ended = 'ended',

    /**
     * The [[Player]] is idle.
     */
    Idle = 'idle',

    /**
     * The [[Player]] is playing back the live stream.
     */
    Playing = 'playing',

    /**
     * The [[Player]] is ready to play back the live stream.
     */
    Ready = 'ready',
  }

  /**
   * The statistics of the [[Player]]'s live stream.
   */
  export interface Stats {
    /**
     * The bitrate of the video stream in bits per second (bps).
     */
    videoBitrate: number;

    /**
     * The number of video frames decoded.
     */
    videoFramesDecoded: number;

    /**
     * The number of video frames dropped.
     */
    videoFramesDropped: number;
  }

  /**
   * A [[Telemetry]] provides facilities for subscribing to event
   * and metric data published by the SDK.
   */
  export import Telemetry = TelemetryExports.Telemetry;

  /**
   * Timed metadata that is sent to the [[Player]] by the live stream source.
   */
  export interface TimedMetadata {
    /**
     * The metadata string.
     */
    metadata: string;

    /**
     * The time when the metadata should be displayed.
     */
    time: number;
  }

  /**
   * Representation of the [[Player]]'s video size.
   */
  export interface VideoDimensions {
    /**
     * Height of the video in pixels.
     */
    height: number;

    /**
     * Width of the video in pixels.
     */
    width: number;
  }
}

// NOTE(mmalavalli): This represents the current log level of the SDK
// and is accessed by Player.logLevel and set by Player.setLogLevel().
let logLevel = Player.LogLevel.Error;

// NOTE(mmalavalli): This contains the VendorPlayer instances created so
// far. Whenever Player.logLevel is updated, the log levels of the VendorPlayer
// instances are updated as well.
const vendorPlayers = new Set<VendorPlayer>();
