import { EventEmitter } from 'events';
import { Player, VendorPlayer } from '../player';

const MAX_PLAYBACK_RATE = 1.05;
const DEFAULT_PLAYBACK_RATE = 1;

// All units are in seconds
const SEEK_BUFFER = 3;
const MIN_LATENCY_PLAYBACK_INCREASE_THRESHOLD = 3;
const MAX_LATENCY_PLAYBACK_INCREASE_THRESHOLD = 5;

/**
 * [[LiveLatencyEventObserver]] listens to player events and monitor live latency values.
 * This observer will then emit events when certain thresholds are detected.
 * @private
 */
export class LiveLatencyEventObserver extends EventEmitter {

  private _active: boolean = false;
  private _isHighLatencyReductionEnabled: boolean;
  private _telemetry: Player.Telemetry;
  private _vendorPlayer: VendorPlayer;

  /**
   * @private
   */
  constructor(
    vendorPlayer: VendorPlayer,
    telemetry: Player.Telemetry,
    isHighLatencyReductionEnabled: boolean,
  ) {
    super();
    this._isHighLatencyReductionEnabled = isHighLatencyReductionEnabled;
    this._telemetry = telemetry;
    this._vendorPlayer = vendorPlayer;

    this._telemetry.subscribe(
      this._increasePlaybackRate,
      (telemetryData: Player.Telemetry.Data) => {
        const { name, playerLiveLatency } = telemetryData as Player.Telemetry.Data.PlaybackQuality.Summary;
        return this._shouldApplyHighLatencyReduction(name, playerLiveLatency)
          && playerLiveLatency < MAX_LATENCY_PLAYBACK_INCREASE_THRESHOLD
          && this._vendorPlayer.getPlaybackRate() < MAX_PLAYBACK_RATE;
      }
    );

    this._telemetry.subscribe(
      this._seekAhead,
      (telemetryData: Player.Telemetry.Data) => {
        const { name, playerLiveLatency } = telemetryData as Player.Telemetry.Data.PlaybackQuality.Summary;
        return this._shouldApplyHighLatencyReduction(name, playerLiveLatency)
          && playerLiveLatency >= MAX_LATENCY_PLAYBACK_INCREASE_THRESHOLD
          && this._vendorPlayer.getBufferDuration() >= MAX_LATENCY_PLAYBACK_INCREASE_THRESHOLD;
      }
    );

    this._telemetry.subscribe(
      this._revertHighLatencyReduction,
      (telemetryData: Player.Telemetry.Data) => {
        const { name, playerLiveLatency } = telemetryData as Player.Telemetry.Data.PlaybackQuality.Summary;
        return this._active && name === 'summary'
          && playerLiveLatency <= MIN_LATENCY_PLAYBACK_INCREASE_THRESHOLD;
      }
    );
  }

  release(): void {
    // NOTE(csantos): This event observer cannot be reused. So release all listeners attached to it.
    this.removeAllListeners(LiveLatencyEventObserver.Event.HighLatencyReductionReverted);
    this.removeAllListeners(LiveLatencyEventObserver.Event.IncreasePlaybackRate);
    this.removeAllListeners(LiveLatencyEventObserver.Event.SeekAhead);

    this._telemetry.unsubscribe(this._increasePlaybackRate);
    this._telemetry.unsubscribe(this._seekAhead);
    this._telemetry.unsubscribe(this._revertHighLatencyReduction);
  }

  private _increasePlaybackRate = () => {
    this._active = true;
    this._vendorPlayer.setPlaybackRate(MAX_PLAYBACK_RATE);
    this.emit(LiveLatencyEventObserver.Event.IncreasePlaybackRate);
  }

  private _revertHighLatencyReduction = () => {
    this._active = false;
    this._vendorPlayer.setPlaybackRate(DEFAULT_PLAYBACK_RATE);
    this.emit(LiveLatencyEventObserver.Event.HighLatencyReductionReverted);
  }

  private _seekAhead = () => {
    this._active = true;
    this._vendorPlayer.setPlaybackRate(DEFAULT_PLAYBACK_RATE);
    const newPosition = this._vendorPlayer.getPosition() + this._vendorPlayer.getBufferDuration() - SEEK_BUFFER;
    this._vendorPlayer.seekTo(newPosition);
    this.emit(LiveLatencyEventObserver.Event.SeekAhead);
  }

  private _shouldApplyHighLatencyReduction(name: string, playerLiveLatency: number): boolean {
    return this._isHighLatencyReductionEnabled && name === 'summary'
      && playerLiveLatency > MIN_LATENCY_PLAYBACK_INCREASE_THRESHOLD
      && this._vendorPlayer.getBufferDuration() >= MIN_LATENCY_PLAYBACK_INCREASE_THRESHOLD;
  }
}

/**
 * @private
 */
export namespace LiveLatencyEventObserver {
  /**
   * @private
   */
  export enum Event {
    HighLatencyReductionReverted = 'high-latency-reduction-reverted',
    IncreasePlaybackRate = 'increase-playback-rate',
    SeekAhead = 'seek-ahead',
  }
}
