// TODO(mmalavalli): Ensure only the vendor sdk version is exported, and not the rest of the package.json fields.
const { dependencies } = require('../package.json');

import {
  create,
  ErrorType,
  isPlayerSupported,
  MediaPlayer as IVSMediaPlayer,
  PlayerConfig,
  PlayerError,
  PlayerEventType,
  PlayerState,
  TextMetadataCue,
} from 'amazon-ivs-player';

import { createError } from './error';
import { Player } from './player';
import { RequestCredentials } from './types';
import { PlaybackUrlEventObserver } from './eventobservers';

const { ErrorCode } = Player.Error;
const PLAYBACK_QUALITY_SUMMARY_PUBLISH_INTERVAL_MS = 3000;

const IVS_ERRORS = new Map();
IVS_ERRORS.set(ErrorType.GENERIC, ErrorCode.PLAYBACK_MEDIA);
IVS_ERRORS.set(ErrorType.AUTHORIZATION, ErrorCode.PLAYBACK_AUTHORIZATION);
IVS_ERRORS.set(ErrorType.INVALID_DATA, ErrorCode.PLAYBACK_INVALID_DATA);
IVS_ERRORS.set(ErrorType.INVALID_PARAMETER, ErrorCode.PLAYBACK_INVALID_PARAMETER);
IVS_ERRORS.set(ErrorType.INVALID_STATE, ErrorCode.PLAYBACK_INVALID_STATE);
IVS_ERRORS.set(ErrorType.NETWORK, ErrorCode.PLAYBACK_NETWORK);
IVS_ERRORS.set(ErrorType.NETWORK_IO, ErrorCode.PLAYBACK_NETWORK_IO);
IVS_ERRORS.set(ErrorType.NOT_AVAILABLE, ErrorCode.PLAYBACK_STREAM_NOT_AVAILABLE);
IVS_ERRORS.set(ErrorType.NOT_SUPPORTED, ErrorCode.PLAYBACK_NOT_SUPPORTED);
IVS_ERRORS.set(ErrorType.NO_SOURCE, ErrorCode.PLAYBACK_NO_SOURCE);
IVS_ERRORS.set(ErrorType.TIMEOUT, ErrorCode.PLAYBACK_TIMEOUT);

/**
 * In order to construct [[Player.Stats]], we need the following internal APIs
 * that are part of the MediaPlayer class.
 * @private
 */
interface MediaPlayerInternal extends IVSMediaPlayer {
  getDecodedFrames: () => number;
  getDroppedFrames: () => number;
  getVideoBitRate: () => number;
  setRebufferToLive: (rebufferToLive: boolean) => void;
  setRequestCredentials: (requestCredentials: RequestCredentials) => void;
}

const createMediaPlayerWithInternalAPIs = (config: PlayerConfig) =>
  create(config) as MediaPlayerInternal;

const vendorPlayerVersion = dependencies['amazon-ivs-player'];

/**
 * Whether the SDK supports the browser. The SDK only supports browsers which are
 * capable of running WebAssembly (WASM).
 */
export const isSupported = isPlayerSupported;

export class MediaPlayer extends Player {

  private _playbackUrlEventObserver?: PlaybackUrlEventObserver;

  constructor(
    playbackUrl: string,
    streamerSid: string,
    options: Player.Options) {
    super(
      playbackUrl,
      streamerSid,
      createMediaPlayerWithInternalAPIs,
      { ...options, vendorPlayerVersion });
  }

  protected _getState(): Player.State {
    return {
      [PlayerState.BUFFERING]: Player.State.Buffering,
      [PlayerState.ENDED]: Player.State.Ended,
      [PlayerState.IDLE]: Player.State.Idle,
      [PlayerState.PLAYING]: Player.State.Playing,
      [PlayerState.READY]: Player.State.Ready,
    }[this._vendorPlayer.getState() as PlayerState];
  }

  protected _reemitVendorPlayerEvents(): () => void {
    const getPlaybackQualitySummary: () => Player.Telemetry.Data.PlaybackQuality.Summary = () => ({
      name: 'summary',
      playerLiveLatency: this._vendorPlayer.getLiveLatency(),
      playerPosition: this._vendorPlayer.getPosition(),
      playerStats: this.stats,
      playerStreamerSid: this._streamerSid,
      playerVolume: this._vendorPlayer.getVolume(),
      timestamp: Date.now(),
      type: 'playback-quality',
    });

    const {
      start: startPublishingPlaybackQualitySummary,
      stop: stopPublishingPlaybackQualitySummary,
    } = Player.telemetry.publishPeriodically(
      getPlaybackQualitySummary,
      PLAYBACK_QUALITY_SUMMARY_PUBLISH_INTERVAL_MS);

    let previousState = this.state;

    const onState = () => {
      const state = this.state;
      this.emit(Player.Event.StateChanged, state);

      const stateChanged: Player.Telemetry.Data.PlaybackState.Changed = {
        from: previousState,
        name: 'changed',
        playerStreamerSid: this._streamerSid,
        timestamp: Date.now(),
        to: state,
        type: 'playback-state',
      };
      Player.telemetry.publish(stateChanged);
      previousState = state;

      if (state === Player.State.Buffering || state === Player.State.Playing) {
        startPublishingPlaybackQualitySummary();
      } else {
        stopPublishingPlaybackQualitySummary();
      }
      if (state === Player.State.Ended) {
        this._release();
      }
    };
    Object.values(PlayerState).forEach(state =>
      this._vendorPlayer.addEventListener(state, onState));

    let previousQuality = this.quality;
    const onQualityChanged = () => {
      const quality = this.quality;
      this.emit(Player.Event.QualityChanged, quality);
      const qualityChanged: Player.Telemetry.Data.PlaybackQuality.QualityChanged = {
        from: previousQuality,
        name: 'quality-changed',
        playerLiveLatency: this._vendorPlayer.getLiveLatency(),
        playerPosition: this._vendorPlayer.getPosition(),
        playerStreamerSid: this._streamerSid,
        playerVolume: this._vendorPlayer.getVolume(),
        timestamp: Date.now(),
        to: quality,
        type: 'playback-quality',
      };
      Player.telemetry.publish(qualityChanged);
      previousQuality = quality;
    };
    this._vendorPlayer.addEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);

    let previousDuration = this.duration;
    const onDurationChanged = (duration: number) => {
      this.emit(Player.Event.DurationChanged, duration);
      const durationChanged: Player.Telemetry.Data.PlaybackQuality.DurationChanged = {
        from: previousDuration,
        name: 'duration-changed',
        playerLiveLatency: this._vendorPlayer.getLiveLatency(),
        playerPosition: this._vendorPlayer.getPosition(),
        playerStreamerSid: this._streamerSid,
        playerVolume: this._vendorPlayer.getVolume(),
        timestamp: Date.now(),
        to: duration,
        type: 'playback-quality',
      };
      Player.telemetry.publish(durationChanged);
      previousDuration = duration;
    };
    this._vendorPlayer.addEventListener(PlayerEventType.DURATION_CHANGED, onDurationChanged);

    const onTextMetadataCue = (textCue: TextMetadataCue) => {
      this.emit(Player.Event.TimedMetadataReceived, {
        metadata: textCue.text,
        time: textCue.startTime,
      });
      const timedMetadataReceived: Player.Telemetry.Data.TimedMetadataTelemetry.Received = {
        name: 'received',
        playerStreamerSid: this._streamerSid,
        timedMetadataTime: textCue.startTime,
        timestamp: Date.now(),
        type: 'timed-metadata',
      };
      Player.telemetry.publish(timedMetadataReceived);
    };
    this._vendorPlayer.addEventListener(PlayerEventType.TEXT_METADATA_CUE, onTextMetadataCue);

    const onRebuffering = () => {
      this.emit(Player.Event.Rebuffering);
      const rebuffering: Player.Telemetry.Data.Playback.Rebuffering = {
        name: 'rebuffering',
        playerPosition: this._vendorPlayer.getPosition(),
        playerState: this._getState(),
        playerStreamerSid: this._streamerSid,
        timestamp: Date.now(),
        type: 'playback',
      };
      Player.telemetry.publish(rebuffering);
    };
    this._vendorPlayer.addEventListener(PlayerEventType.REBUFFERING, onRebuffering);

    const onError = ({ code, message, source, type }: PlayerError) => {
      this._disconnect();
      const errorExplanation = `${code} - ${message} - ${source}`;
      const error = createError(IVS_ERRORS.get(type), message, errorExplanation);
      this._emitPlaybackError(error);
    };
    if (!this._playbackUrlEventObserver) {
      this._playbackUrlEventObserver = new PlaybackUrlEventObserver(this._vendorPlayer, this._playbackUrl);
    }
    this._playbackUrlEventObserver.on(PlayerEventType.ERROR, onError);

    const onVolumeChanged = (level: number) => this.emit(Player.Event.VolumeChanged, level);
    this._vendorPlayer.addEventListener(PlayerEventType.VOLUME_CHANGED, onVolumeChanged);

    const onSeekCompleted = (position: number)  => {
      this.emit(Player.Event.SeekCompleted, position);
      const seekCompleted: Player.Telemetry.Data.Playback.SeekCompleted = {
        name: 'seek-completed',
        playerPosition: this._vendorPlayer.getPosition(),
        playerState: this._getState(),
        playerStreamerSid: this._streamerSid,
        timestamp: Date.now(),
        type: 'playback',
      };
      Player.telemetry.publish(seekCompleted);
    };
    this._vendorPlayer.addEventListener(PlayerEventType.SEEK_COMPLETED, onSeekCompleted);

    return () => {
      stopPublishingPlaybackQualitySummary();
      Object.values(PlayerState).forEach(state =>
        this._vendorPlayer.removeEventListener(state, onState));
      this._vendorPlayer.removeEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);
      this._vendorPlayer.removeEventListener(PlayerEventType.DURATION_CHANGED, onDurationChanged);
      this._vendorPlayer.removeEventListener(PlayerEventType.TEXT_METADATA_CUE, onTextMetadataCue);
      this._vendorPlayer.removeEventListener(PlayerEventType.REBUFFERING, onRebuffering);
      this._vendorPlayer.removeEventListener(PlayerEventType.VOLUME_CHANGED, onVolumeChanged);
      this._vendorPlayer.removeEventListener(PlayerEventType.SEEK_COMPLETED, onSeekCompleted);

      if (this._playbackUrlEventObserver) {
        this._playbackUrlEventObserver.release();
      }
    };
  }
}
