import { Buffer } from 'safe-buffer';

/**
 * @private
 * Decodes a base64 string. This is a more robust implementation of window.atob
 * which should be able to handle unicode problems using node buffers.
 */
export function decodeBase64Str(base64Str: string): string {
  return Buffer.from(base64Str, 'base64').toString();
}
